@import (once) "./vars";
@import (once) "./media";

body {
  background: @mainBgColor;
}

html, body, #root {
  height: 100%;
}

a {
  color: @primaryColor;
  text-decoration: none;
  &:hover {
    color: darken(@primaryColor, 10%);
    text-decoration: none;
  }
}

.loading {
  .spinner-border {
    border-color: @primaryColor;
    border-right-color: transparent !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.card {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid @lightBorderColor;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px 0 @shadowColor;

  .card-header,
  .card-footer {
    background: @lightBgColor;
  }
  .card-body + .card-header {
    border-top: 1px solid rgba(0, 0, 0, 0.175);
  }
}

.table {
  th {
    color: @secondaryColor;
  }
  thead, tbody {
    border: none !important;
    & > tr > th, td {
      border-bottom: 1px solid @lightColor !important;
    }
  }
  &.table-wide {
    thead > tr > th:first-child, td:first-child,
    tbody > tr > th:first-child, td:first-child {
      padding-left: 15px;
    }
    thead > tr > th:last-child, td:last-child,
    tbody > tr > th:last-child, td:last-child {
      padding-right: 15px;
    }
  }
}

.btn {
  &.btn-xs {
    font-size: .7rem;
    padding: .163rem .45rem;
  }
  &.btn-primary {
    color: #fff;
    background: @primaryColor;
    border-color: @primaryColor;
    &:focus {
      box-shadow: 0 0 0 0.2rem fade(@primaryColor, 60%);
    }
  }
  &.btn-secondary {
    color: #fff;
    background: @secondaryColor;
    border-color: @secondaryColor;
    &:focus {
      box-shadow: 0 0 0 0.2rem fade(@secondaryColor, 60%);
    }
  }
  &.btn-success {
    color: #fff;
    background: @successColor;
    border-color: @successColor;
    &:focus {
      box-shadow: 0 0 0 0.2rem fade(@successColor, 60%);
    }
  }
  &.btn-danger {
    color: #fff;
    background: @dangerColor;
    border-color: @dangerColor;
    &:focus {
      box-shadow: 0 0 0 0.2rem fade(@dangerColor, 60%);
    }
  }
  &.btn-warning {
    color: #000;
    background: @warningColor;
    border-color: @warningColor;
    &:focus {
      box-shadow: 0 0 0 0.2rem fade(@warningColor, 60%);
    }
  }
  &.btn-info {
    color: #000;
    background: @infoColor;
    border-color: @infoColor;
    &:focus {
      box-shadow: 0 0 0 0.2rem fade(@infoColor, 60%);
    }
  }
  &.btn-light {
    color: #000;
    background: @lightColor;
    border-color: @lightColor;
    &:focus {
      box-shadow: 0 0 0 0.2rem fade(@lightColor, 60%);
    }
  }
  &.btn-dark {
    color: #fff;
    background: @darkColor;
    border-color: @darkColor;
    &:focus {
      box-shadow: 0 0 0 0.2rem fade(@darkColor, 60%);
    }
  }
  &.btn-outline-primary {
    color: @primaryColor;
    border-color: @primaryColor;
    &:hover {
      color: #fff;
      background: @primaryColor;
    }
    &:focus {
      color: #fff;
      background: @primaryColor;
      box-shadow: 0 0 0 0.2rem fade(@primaryColor, 60%);
    }
  }
  &.btn-outline-secondary {
    color: @secondaryColor;
    border-color: @secondaryColor;
    &:hover {
      color: #fff;
      background: @secondaryColor;
    }
    &:focus {
      color: #fff;
      background: @secondaryColor;
      box-shadow: 0 0 0 0.2rem fade(@secondaryColor, 60%);
    }
  }
  &.btn-outline-success {
    color: @successColor;
    border-color: @successColor;
    &:hover {
      color: #fff;
      background: @successColor;
    }
    &:focus {
      color: #fff;
      background: @successColor;
      box-shadow: 0 0 0 0.2rem fade(@successColor, 60%);
    }
  }
  &.btn-outline-danger {
    color: @dangerColor;
    border-color: @dangerColor;
    &:hover {
      color: #fff;
      background: @dangerColor;
    }
    &:focus {
      color: #fff;
      background: @dangerColor;
      box-shadow: 0 0 0 0.2rem fade(@dangerColor, 60%);
    }
  }
  &.btn-outline-warning {
    color: @warningColor;
    border-color: @warningColor;
    &:hover {
      color: #000;
      background: @warningColor;
    }
    &:focus {
      color: #fff;
      background: @warningColor;
      box-shadow: 0 0 0 0.2rem fade(@warningColor, 60%);
    }
  }
  &.btn-outline-info {
    color: @infoColor;
    border-color: @infoColor;
    &:hover {
      color: #000;
      background: @infoColor;
    }
    &:focus {
      color: #fff;
      background: @infoColor;
      box-shadow: 0 0 0 0.2rem fade(@infoColor, 60%);
    }
  }
  &.btn-outline-light {
    color: @lightColor;
    border-color: @lightColor;
    &:hover {
      color: #000;
      background: @lightColor;
    }
    &:focus {
      color: #fff;
      background: @lightColor;
      box-shadow: 0 0 0 0.2rem fade(@lightColor, 60%);
    }
  }
  &.btn-outline-dark {
    color: @darkColor;
    border-color: @darkColor;
    &:hover {
      color: #fff;
      background: @darkColor;
    }
    &:focus {
      color: #fff;
      background: @darkColor;
      box-shadow: 0 0 0 0.2rem fade(@darkColor, 60%);
    }
  }
}

.form-control {
  &.form-control-xs {
    font-size: .7rem;
    padding: .163rem .45rem;
  }
}

.form-select {
  &.form-select-xs {
    font-size: .7rem;
    padding: .163rem .45rem;
  }
}

.input-group {
  &.input-group-xs {
    .btn,
    .form-control,
    .form-select,
    .input-group-text {
      font-size: .7rem;
      padding: .163rem .45rem;
    }
  }
}
.field-group {
  display: flex;
  flex-wrap: nowrap;
  .field-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
  }

  .btn,
  .form-control,
  .field-group-text,
  .input-group-text {
    &:not(:first-child) {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & > .form-field {
    &:not(:first-child) {
      & > .btn,
      & > .btn-group > .btn,
      & > .form-control,
      & > .input-group > .form-control,
      & > .input-group > .input-group-text  {
        //margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:not(:last-child) {
      & > .btn,
      & > .btn-group > .btn,
      & > .form-control,
      & > .input-group > .form-control,
      & > .input-group > .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
.btn-group {
  &.btn-group-xs {
    .btn {
      font-size: .7rem;
      padding: .163rem .45rem;
    }
  }
}

.bg-primary {
  color: #fff;
  background: @primaryColor !important;
}
.bg-secondary {
  color: #fff;
  background: @secondaryColor !important;
}
.bg-success {
  color: #fff;
  background: @successColor !important;
}
.bg-danger {
  color: #fff;
  background: @dangerColor !important;
}
.bg-warning {
  color: #000;
  background: @warningColor !important;
}
.bg-info {
  color: #000;
  background: @infoColor !important;
}
.bg-light {
  color: #000;
  background: @lightColor !important;
}
.bg-dark {
  color: #fff;
  background: @darkColor !important;
}

.text-primary {
  color: @primaryColor !important;
}
.text-secondary {
  color: @secondaryColor !important;
}
.text-success {
  color: @successColor !important;
}
.text-danger {
  color: @dangerColor !important;
}
.text-warning {
  color: @warningColor !important;
}
.text-info {
  color: @infoColor !important;
}
.text-light {
  color: @lightColor !important;
}
.text-dark {
  color: @darkColor !important;
}

.nav-pills {
  .nav-link.active {
    background: @primaryColor;
  }
}

.notification {
  &.notification-success {
    .toast-header {
      background: @successColor !important;
      color: #fff !important;
    }
  }
  &.notification-info {
    .toast-header {
      background: @infoColor !important;
      color: #000 !important;
    }
  }
  &.notification-warning {
    .toast-header {
      background: @warningColor !important;
      color: #000 !important;
    }
  }
  &.notification-error {
    .toast-header {
      background: @dangerColor !important;
      color: #fff !important;
    }
  }
}

.dropdown-menu {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid @lightBorderColor;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px 0 @shadowColor;
  .dropdown-item:hover {
    background: fade(@primaryColor, 20%);
  }
  .dropdown-item.active {
    background: fade(@primaryColor, 90%);
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-notice {
  font-size: .8rem;
  line-height: 1;
}

.breadcrumb {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: @primaryColor;
}

.max-height {
  height: 100%;
}

.calendar-sheet {
  border: 1px solid @lightBorderColor;
  .calendar-header {
    & > div {
      border: 1px solid @lightBorderColor;
    }
  }
  .calendar-body {
    .calendar-week {
      & > div {
        border: 1px solid @lightBorderColor;
        &.calendar-date {
          &.today {
            color: @primaryColor;
            font-weight: bold;
          }
          &.active {
            color: #fff;
            background: @primaryColor;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.item-block {
  border: 1px solid @lightBorderColor;
  border-radius: 3px;
  margin: 15px 0;
  .prop {
    color: @secondaryColor;
  }
  .item-body {
    padding: 7px;
  }
  .item-controls {
    padding: 7px;
    border-top: 1px solid @lightBorderColor;
  }
}

.dropdown-icon {
  display: flex!important;
  flex-wrap: wrap;
  width: 170px;
  .dropdown-item {
    padding: 3px 5px 5px 5px;
  }
}